var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dashboard"},[_c('div',{staticClass:"Greeting"},[_c('h2',{staticClass:"fw-normal mb-3 blue-dark fs-5"},[_vm._v(" Hello, "+_vm._s(_vm._f("getUserFullName")(_vm.getAuthenticatedUser))+" ")])]),(_vm.customDashboardsList && _vm.customDashboardsList.length)?_c('div',[_c('div',{staticClass:"dashboard-tabs"},[_c('el-tabs',{on:{"tab-click":_vm.changeDashboard},model:{value:(_vm.activeCustomDashboardId),callback:function ($$v) {_vm.activeCustomDashboardId=$$v},expression:"activeCustomDashboardId"}},_vm._l((_vm.customDashboardsList),function(item,index){return _c('el-tab-pane',{key:index + item._id,attrs:{"label":item.name,"name":item._id}})}),1),(
          _vm.activeCustomDashboard &&
          ((_vm.activeCustomDashboard.allCustomFilters &&
            _vm.activeCustomDashboard.allCustomFilters.length) ||
            _vm.activeCustomDashboard.includeGlobalDateFilter)
        )?_c('el-row',{staticClass:"dashboard-filters"},[_c('el-col',{staticStyle:{"border-bottom":"#d2dcfa solid 1px","margin-bottom":"5px"},attrs:{"span":24}},[_c('CustomDashboardFilters',{attrs:{"allDashboardstats":_vm.activeCustomDashboard}})],1)],1):_vm._e(),(
          _vm.activeCustomDashboard &&
          _vm.activeCustomDashboard.allStatComponents &&
          _vm.activeCustomDashboard.allStatComponents.length
        )?_c('el-row',[_c('el-col',{staticStyle:{"margin-top":"10px"},attrs:{"span":24}},[_c('CustomDashboardStats',{attrs:{"allDashboardstats":_vm.activeCustomDashboard,"hide_options":true,"isFromApplicationuser":true,"refresh":_vm.refresh,"permissionsSet":_vm.permissionsSet}})],1)],1):_vm._e()],1),(
        _vm.customDashboardsList &&
        _vm.customDashboardsList.length &&
        _vm.activeCustomDashboard &&
        _vm.activeCustomDashboard.allStatComponents &&
        _vm.activeCustomDashboard.allStatComponents.length
      )?_c('hr'):_vm._e(),(
        _vm.customDashboardsList &&
        _vm.customDashboardsList.length &&
        _vm.activeCustomDashboard
      )?_c('el-row',{attrs:{"gutter":25}},[(!_vm.activeCustomDashboard.allTableComponents.length)?_c('div',[_c('el-empty',{attrs:{"description":"No tables configured"}})],1):_vm._e(),_c('el-scrollbar',{attrs:{"wrap-style":"height: calc(100vh - 100px) !important;"}},[_c('div',{staticClass:"wrapper-grid"},[_c('draggable',{staticClass:"drag-grid",attrs:{"disabled":!_vm.activeCustomDashboard.allowUserChangeOrder},on:{"end":_vm.switchPositions}},_vm._l((_vm.activeCustomDashboard.allTableComponents),function(component,index){return _c('div',{key:component.entity_id +
                '_' +
                component.keyValue +
                '_table_' +
                index,staticClass:"grid-item",style:(_vm.expendedIndexs.indexOf(index) != -1 || _vm.getIsMobile
                  ? 'grid-column: 1/3;'
                  : '')},[(component.type == 'TABLE')?_c('CustomDashboardTable',{attrs:{"component":component,"entity_id":component.entity_id,"filter_id":component.filter_id,"index":index,"globalVariablesData":_vm.globalVariablesData,"hide_options":true,"permissionsSet":_vm.permissionsSet,"hasViewPermission":_vm.checkPermissions(component.entity_id, 'VIEW'),"hasEditPermission":_vm.checkPermissions(component.entity_id, 'EDIT'),"isFromApplicationuser":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'CHART')?_c('CustomDashboardPieChart',{attrs:{"component":component,"hide_options":true,"index":index,"isFromApplicationuser":true,"hasViewPermission":_vm.checkPermissions(component.entity_id, 'VIEW'),"hasEditPermission":_vm.checkPermissions(component.entity_id, 'EDIT')},on:{"expendTable":_vm.expendTable}}):(component.type == 'CALENDAR')?_c('CustomDashboardCalendar',{attrs:{"component":component,"index":index,"hide_options":true,"isFromApplicationuser":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'LOGINS')?_c('CustomDashboardLogins',{attrs:{"component":component,"index":index,"hide_options":true,"isFromApplicationuser":true},on:{"expendTable":_vm.expendTable}}):_vm._e()],1)}),0)],1)])],1):_vm._e()],1):_c('div',[_c('el-empty',{attrs:{"description":"No components configured"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }